import React from "react";
import { Link } from "gatsby";

import AboutUsImg from "../../../images/about-us-img.svg";

const About = () => {
  return (
    <section className="about">
      <div className="about-content container">
        <div className="about-content-left" data-aos="fade-down">
          <h3 className="heading-secondary about-content-left-heading">
            We are passionate experts who love what they do
          </h3>
          <p className="paragraph-primary about-content-left-text">
            As a values-driven software development company, we specialize in
            custom software development, digital transformation, and IT
            consultancy, helping clients across various industries, including
            Retail, Finance and Insurance, take advantage of emerging
            technologies.
          </p>
          <p className="paragraph-primary about-content-left-text">
            Our expert and innovative approach enables us to deliver tailored
            digital solutions that satisfy our clients' business needs and
            goals. For us, building projects is more than just developing
            software; we strive to facilitate business expansion in the most
            effective way possible.
          </p>
          <div className="about-content-left-btn">
            <Link to="/about" className="btn btn-primary">
              Get to know us better
            </Link>
          </div>
        </div>
        <div className="about-content-right" data-aos="fade-up">
          <div className="about-content-right-item">
            <img src={AboutUsImg} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
