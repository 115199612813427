import React from "react";

const ServiceItem = ({ title, description, link }) => {
  return (
    <div
      className="services-content-wrapper-item"
      data-aos="fade-up"
      data-aos-delay="100"
    >
      <img loading="lazy" src={link} alt="" />
      <h3 className="services-content-wrapper-item-heading heading-third">
        {title}
      </h3>
      <p className="services-content-wrapper-item-paragraph paragraph-primary">
        {description}
      </p>
    </div>
  );
};

export default ServiceItem;
