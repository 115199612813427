import React from "react";

const InformationText = () => {
  return (
    <section className="information-text">
      <div
        className="information-text-content container"
        data-aos="fade-up"
        data-aos-delay={200}
      >
        <h2 className="heading-primary">
          We outsource <span className="heading-primary">specialists</span> and
          develop products that assist companies in achieving success
        </h2>
      </div>
    </section>
  );
};

export default InformationText;
