import React from "react";

import TechnologiesImg from "../../../images/technologies.png";

const Technologies = () => {
  return (
    <section className="technologies">
      <div className="technologies-content container">
        <div className="technologies-content-left" data-aos="fade-up">
          <div className="technologies-content-left-item">
            <img loading="lazy" src={TechnologiesImg} alt="" />
          </div>
        </div>
        <div className="technologies-content-right" data-aos="fade-down">
          <h3 className="heading-secondary">
            Leading with technology and excellence
          </h3>
          <p className="paragraph-primary">
            Our team possesses advanced proficiency in the most in-demand
            programming languages, such as Python, Java, and JavaScript.
            We are committed to ongoing learning and skill development,
            which has resulted in a broad range of expertise that allows us to
            approach projects with confidence.
          </p>
          <p className="paragraph-primary">
            What distinguishes us is our ability to function as a
            cross-functional team, with a comprehensive understanding of how
            diverse areas of expertise converge and enhance one another,
            enabling us to take a holistic approach to projects.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Technologies;
