import React from "react";
import { Link } from "gatsby";

import ServiceItem from "./components/ServiceItem";
import ArrowButton from "../../ArrowButton";

import MobileIcon from "../../../images/icons/mobileIcon.png";
import WebIcon from "../../../images/icons/webIcon.png";
import ConsultingIcon from "../../../images/icons/consultingIcon.png";
import SmartTvIcon from "../../../images/icons/smartTvIcon.png";
import IotIcon from "../../../images/icons/iotIcon.png";
import EcommerceIcon from "../../../images/icons/ecommerceIcon.png";

const Services = () => {
  return (
    <section className="services">
      <div className="services-content container">
        <div className="services-content-top">
          <div className="servives-content-top-text">
            <h2
              className="services-content-top-text-heading heading-secondary"
              data-aos="fade-up"
              data-aos-delay="50"
            >
              We specialize in the best quality software, consulting and expertise
            </h2>
            <p
              className="services-content-top-text-paragraph paragraph-primary"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              GammaSoft is here to take your idea from concept to reality. We
              will help you in developing and rolling out your IT systems.
            </p>
          </div>
          <div
            className="services-content-top-btn"
            data-aos="fade-up"
            data-aos-delay="150"
          >
            <Link to="/services" className="btn btn-secondary">
              <ArrowButton text="Explore our offerings" />
            </Link>
          </div>
        </div>
        <div className="services-content-wrapper">
          <ServiceItem
            title="Mobile Development"
            description="Staying connected with your clients and employees is made possible through mobile apps that function across multiple devices."
            link={MobileIcon}
          />
          <ServiceItem
            title="Web Development"
            description="A goal-oriented, responsive website will boost your business. Choose the right application for your needs."
            link={WebIcon}
          />
          <ServiceItem
            title="Smart TV"
            description="Our expertise includes designing and developing applications for Smart TV platforms, as well as overseeing their distribution."
            link={SmartTvIcon}
          />
          <ServiceItem
            title="IoT / Smart City"
            description="From the creation and design of smart city solutions to the management and optimization of IoT operations, we offer end-to-end services."
            link={IotIcon}
          />
          <ServiceItem
            title="eCommerce"
            description="Our solutions enable businesses to capitalize on the vast potential of the online marketplace and drive sales growth."
            link={EcommerceIcon}
          />
          <ServiceItem
            title="Consulting & Expertise"
            description="Our IT consulting services encompass a broad range of areas, including technical problem-solving and expert advice on proper system scaling."
            link={ConsultingIcon}
          />
        </div>
      </div>
    </section>
  );
};

export default Services;
