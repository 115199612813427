import * as React from "react";
import HeroSection from "./components/HeroSection";
import Clients from "./components/Clients";
import InformationText from "./components/InformationText";
import Services from "./components/Services";
import Technologies from "./components/Technologies";
import About from "./components/About";
import OurWork from "./components/OurWork";
import { Seo } from "../SEO/Seo.jsx";
import CTABanner from "../CTABanner/CTABanner";

import MainMenu from "../MainMenu/MainMenu";
import Footer from "../Footer/Footer.jsx";

import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const Homepage = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  return (
    <main>
      <Seo
        title="Gammasoft: Custom Software Development Company"
        description="Gammasoft is a custom software development company that builds software tailored to your unique needs. Our expert team believes in the journey of creating great software, not just reaching a destination. Contact us today to start your journey!"
      />
      <MainMenu />
      <HeroSection />
      <Clients />
      <Services />
      <InformationText />

      <Technologies />
      <About />
      <OurWork />
      <CTABanner />
      <Footer />
    </main>
  );
};

export default Homepage;
