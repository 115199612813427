import React from "react";
import { Link } from "gatsby";

import ImageFirst from "../../../images/thumbs/ecobins-thumb.webp";
import ImageThird from "../../../images/thumbs/cna-thumb.webp";
import ImageSecond from "../../../images/thumbs/autoria-thumb.webp";
import ArrowButton from "../../ArrowButton";

const OurWork = () => {
  return (
    <section className="our-work">
      <div className="our-work-content container">
        <h3 className="heading-secondary" data-aos="fade-up">
          Discover our work
        </h3>
      </div>
      <div className="our-work-content-list container">
        <div
          className="our-work-content-list-item"
          data-aos="fade-up"
          data-aos-delay={100}
        >
          <div className="our-work-content-list-item-left">
            <img loading="lazy" src={ImageFirst} alt="" />
          </div>
          <div className="our-work-content-list-item-right">
            <div className="our-work-content-list-item-right-top">
              <h3 className="heading-third">EcoBins</h3>
              <span className="custom-tag">IOT / Smart City</span>
            </div>
            <p className="paragraph-primary">
              We developed electronic devices, web-based IoT management, a
              mobile app for drivers, and a touchscreen app for sorting stations
              in a comprehensive project aimed at waste management and
              recycling. ♻️
            </p>
            <div className="our-work-content-list-item-right-bottom">
              <Link to="/portfolio/ecobins">Case studies</Link>
            </div>
          </div>
        </div>
        <div
          className="our-work-content-list-item"
          data-aos="fade-up"
          data-aos-delay={150}
        >
          <div className="our-work-content-list-item-left">
            <img loading="lazy" src={ImageSecond} alt="" />
          </div>
          <div className="our-work-content-list-item-right">
            <div className="our-work-content-list-item-right-top">
              <h3 className="heading-third">Autoria</h3>
              <span className="custom-tag">eCommerce</span>
            </div>
            <p className="paragraph-primary">
              Our system offers auto parts dealers convenient interfaces,
              providing access to the largest selection of new and used car and
              parts sales nationwide. With the ability to compare prices, you
              won't have to worry about overpaying 🏷️.
            </p>
            <div className="our-work-content-list-item-right-bottom">
              <Link to="/portfolio/autoria">Case studies</Link>
            </div>
          </div>
        </div>
        <div
          className="our-work-content-list-item"
          data-aos="fade-up"
          data-aos-delay={200}
        >
          <div className="our-work-content-list-item-left">
            <img loading="lazy" src={ImageThird} alt="" />
          </div>
          <div className="our-work-content-list-item-right">
            <div className="our-work-content-list-item-right-top">
              <h3 className="heading-third">Channel News Asia</h3>
              <span className="custom-tag">Smart TV</span>
            </div>
            <p className="paragraph-primary">
              We developed an application for Media Corp, the largest news
              television station in Southeast Asia, that delivers news and video
              content to Smart TV platforms. 📺
            </p>
            <div className="our-work-content-list-item-right-bottom">
              <Link to="/portfolio/channel-news-asia">Case studies</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="our-work-btn" data-aos="fade-up" data-aos-delay={250}>
        <Link to="/portfolio" className="btn btn-secondary">
          <ArrowButton text="Check out more" />
        </Link>
      </div>
    </section>
  );
};

export default OurWork;
