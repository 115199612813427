import * as React from "react";
import { Link } from "gatsby";
import ArrowButton from "../../ArrowButton";

import WorldMapImg from "../../../images/world-map.png";

const HeroSection = () => {
  return (
    <section
      className="hero"
      style={{ backgroundImage: `url(${WorldMapImg})` }}
    >
      <div className="hero-text container">
        <h1 className="heading-primary">
          We turn your ideas into powerful digital products
        </h1>
        <p className="paragraph-primary">
          GammaSoft is a company that can build any software you need. We take
          pride in our work and believe in the power of meeting your requirements with
          the right solution.
        </p>
        <div className="hero-buttons">
          <Link to="/services" className="btn btn-secondary">
            <ArrowButton text="Explore our services" />
          </Link>

          <Link to="/portfolio" className="btn btn-primary">
            View our projects
          </Link>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
