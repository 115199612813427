import * as React from "react";

import "../styles/styles.scss";

import Homepage from "../components/Homepage/Homepage.jsx";

import { motion, useScroll } from "framer-motion";

const IndexPage = () => {
  const { scrollYProgress } = useScroll();

  return (
    <>
      <motion.div
        className="progress-bar"
        style={{ scaleX: scrollYProgress }}
      />
      <Homepage />
    </>
  );
};

export default IndexPage;
